import {
    BrowserRouter as Router,
    Link
} from "react-router-dom";
import './../App.css'

const Header = () => {
    return (
        <div className="headerContainer">
            Leo
        </div>
    )
}

export default Header