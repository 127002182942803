import './App.css';
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";
import app_store_badge from './images/badge.svg'
import leo_logo from './images/logo.png'
import Footer from './Components/Footer';
import TextBlock from './Components/TextBlock';
import logo from './images/logo_new.png'
import Header from './Components/Header';


const Home = () => {
  return (
    <div className="App">


      <div className="content">
        <div className="content__column content__left">
          <Header />
          <TextBlock />
          <div className="footerContainer">
            <Footer />
          </div>
        </div>
        <div className="content__column content__right">
        </div>
        <div className="footerContainer-mobile">
            <Footer />
          </div>
      </div>
    </div>
  );
}

export default Home;
