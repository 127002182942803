import {
    BrowserRouter as Router,
    Link
} from "react-router-dom";
import './../App.css'

const Footer = () => {
    return (
        <div className="footer">
            <div className="footer__contact">Contact us at <span><a href="mailto:leoartapp@gmail.com">leoartapp@gmail.com</a></span> </div>
            <div className="footer__divider">{"  "}|{"  "}</div>
            <Link to="/privacypolicy" className="footer__privacy">Privacy Policy</Link>
        </div>
    )
}

export default Footer