import app_store_badge from '../images/badge.svg'
import './../App.css'

const TextBlock = () => {
    return (
        <div className="textContainer">

            <div className="cta">


                <h1>Learn art history ––
                    <br />
                    the easy way</h1>
                {/* <p>Ready to level up your art history knowledge? Leo brings art history to the palm of your hand. Explore the world's most admired artworks, uncover the fascinating stories behind legendary artists, and cultivate your inner connoisseur. Embark on a journey of artistic discovery – download Leo from the App Store today!
                </p> */}
                {/* <p>Level up your art history knowledge with Leo! Explore admired artworks, uncover fascinating artist stories, and cultivate your inner connoisseur. Download Leo from the App Store today and embark on an artistic journey!</p> */}
                <p>Level up your art history knowledge with Leo! Explore the world's most admired artworks, uncover the fascinating stories behind legendary artists, and nurture your inner connoisseur.  Dive into the mesmerizing world of art history with Leo as your guide. Download from the App Store today!</p>
            </div>
            <div className="badge">
                <a href="https://apps.apple.com/us/app/leo-art/id1668017685">
                    <img src={app_store_badge} alt="App Store badge" />
                </a>
            </div>
        </div>


    )

}

export default TextBlock